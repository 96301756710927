import {
	hex_md5
} from "./MD5.js"
import UTF from './utf.js' // 中文转码方法

const key = '486knf6asd229qefs4gdgdgy22l6gm23'
function paramsStrSort(paramsStr){
	//再加上后端给的key值
	//切割后用&拼接字符串
	let newStr = paramsStr+'&key='+ key
	return hex_md5(newStr)
}

function objKeySort(obj) {//排序的函数
    var newkey = Object.keys(obj).sort();
　　//先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    var newObj = {};//创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
        newObj[newkey[i]] = obj[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj;//返回排好序的新对象
}

//传入需要签名的参数
const chineseRegex = /^[\u4e00-\u9fa5]+$/;
export function postSign(obj,type) {
	let params = objKeySort(obj)
	let arr = []
	if(typeof params == 'object'){
		// 去除空参数
		for(let i in params.allbuynum){
			for(let j in params.allbuynum[i]){
				for(let k in params.allbuynum[i][j]){
					if(chineseRegex.test(params.allbuynum[i][j][k].leixing1)){
						 params.allbuynum[i][j][k].leixing1 = encodeURIComponent(params.allbuynum[i][j][k].leixing1)
						 // delete params.allbuynum[i][j][k].leixing1
					}
					if(chineseRegex.test(params.allbuynum[i][j][k].leixing2)){
						params.allbuynum[i][j][k].leixing2 = encodeURIComponent(params.allbuynum[i][j][k].leixing2)
					}
					if(chineseRegex.test(params.allbuynum[i][j][k].leixing3)){
						params.allbuynum[i][j][k].leixing3 = encodeURIComponent(params.allbuynum[i][j][k].leixing3)
					}
				}
			}
		}
		for(let i in params){
			if(!params[i]){
				delete params[i]
			}
			if(type == 2){
				if(chineseRegex.test(params[i])){
					if(params[i] == '腾讯分分彩' ){
						params[i] = params[i]
					} else if(params[i] == '排列三'){
						params[i] = params[i]
					} else {
						params[i] = UTF(params[i])
					}
				}
				if(params[i] instanceof Array){
					params[i] = JSON.stringify(params[i])
				}
			}
		}
		
		
		for(let i in params){
			arr.push(`&${i}=${params[i]}`)
		}
	}
	
	let str = arr.join('').slice(1);
	let _sign = paramsStrSort(str)
	//返回生成的签名
	return _sign;
}