import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/main.css'
import SvgIcon from '@/components/svg-icon.vue'
import { registerDirective } from '@/utils/globalMethod'
import { Loading } from 'element-ui';
import GoEasy from 'goeasy'



Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(ElementUI)
Vue.use(Loading)

Vue.use(registerDirective)
Vue.component('svg-icon',SvgIcon)
// 引入聊天室
Vue.prototype.globalData = {
  currentUser: null
};
const goEasy = GoEasy.getInstance({
  host: 'hangzhou.goeasy.io', //应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
  appkey: 'BC-773fa32c2db34b4080c3077cf8cf14cf', // common key,
  modules: ['im'],
});

Vue.prototype.GoEasy = GoEasy;
Vue.prototype.goEasy = goEasy;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

